<template>
  <v-container v-if="!_.isEmpty(borrowerEditable)">
    <!-- Banner header -->
    <Banner :title="`Prestataria ${borrowerEditable.migration_id ? `(ID de migración: ${borrowerEditable.migration_id})` : ''}`" />

    <!-- Form profile data -->
    <v-card class="my-8 pa-5">
      <v-card-text class="pa-5">
        <v-form ref="formEditBorrower" v-model="formEditBorrowerValid">
          <v-text-field v-model="borrowerEditable.first_name" label="Nombre" :rules="formRules.textRules" :readonly="!editable" />
          <v-text-field v-model="borrowerEditable.last_name" label="Apellidos" :rules="formRules.textRules" :readonly="!editable" />
          <v-text-field v-model="borrowerEditable.email" type="email" label="Email" :rules="formRules.emailRules" :readonly="!editable" />
          <v-text-field
            v-model="borrowerEditable.identity_document"
            label="Documento de identidad"
            :rules="formRules.textRules"
            :readonly="!editable"
          />
          <DatePicker
            :parent_model="borrowerEditable.date_of_birth"
            label="Fecha de nacimiento"
            :type_birthdate="true"
            :required="true"
            :readonly="!editable"
            @change="borrowerEditable.date_of_birth = $event"
          />
          <v-text-field v-model="borrowerEditable.phone" label="Teléfono" :rules="formRules.phoneRules" :readonly="!editable" />
          <v-text-field
            v-if="borrowerEditable.borrower"
            v-model="borrowerEditable.borrower.num_credits"
            label="Número de créditos"
            readonly
          />
          <v-text-field
            v-if="borrowerEditable.borrower"
            v-model="borrowerEditable.borrower.status_profile"
            label="Estado del perfil"
            readonly
          />
          <CountryAutocomplete
            :listener="borrower"
            :countryId="borrowerEditable.country"
            :editable="editable"
            @selected="handleCountrySelection($event)"
          />
          <v-autocomplete
            v-if="!_.isEmpty(borrowerEditable.country)"
            v-model="borrowerEditable.city"
            :items="cities"
            item-text="name"
            item-value="id"
            label="Ciudad"
            :rules="formRules.textRules"
            :readonly="!editable"
          />
          <v-text-field
            v-if="!_.isEmpty(borrowerEditable.city)"
            v-model="borrowerEditable.zone"
            label="Zona"
            :readonly="!editable"
            :rules="formRules.textRules"
          />
          <v-text-field v-model="borrowerEditable.address.name" label="Dirección" :rules="formRules.textRules" :readonly="!editable" />
          <v-select
            v-model="borrowerEditable.borrower.finance_skills"
            :items="[
              { name: 'Sin info', id: 0 },
              { name: 'Nulo / Tradicional', id: 1 },
              { name: 'Avanzado limitado', id: 2 },
              { name: 'Nulo moderno', id: 3 },
              { name: 'Avanzado moderno', id: 4 },
              { name: 'Básico potencial', id: 5 },
              { name: 'Avanzado potencial', id: 6 }
            ]"
            item-text="name"
            item-value="id"
            label="Habilidades en finanzas"
            :readonly="!editable"
            :rules="[(v) => v >= 0 || 'Campo obligatorio']"
          />
          <v-select
            v-model="borrowerEditable.borrower.marketing_skills"
            :items="[
              { name: 'Sin info', id: 0 },
              { name: 'Básico tradicional / baja escala', id: 1 },
              { name: 'Avanzado limitado / baja escala', id: 2 },
              { name: 'Básico potencial / baja escala', id: 3 },
              { name: 'Avanzado potencial / baja escala', id: 4 },
              { name: 'Básico tradicional / alta escala', id: 5 },
              { name: 'Avanzado limitado / alta escala', id: 6 },
              { name: 'Básico potencial / alta escala', id: 7 },
              { name: 'Avanzado potencial / alta escala', id: 8 }
            ]"
            item-text="name"
            item-value="id"
            label="Habilidades en marketing"
            :readonly="!editable"
            :rules="[(v) => v >= 0 || 'Campo obligatorio']"
          />
          <v-select
            v-model="borrowerEditable.borrower.innovation_skills"
            :items="[
              { name: 'Sin info', id: 0 },
              { name: 'Básico tradicional / baja escala', id: 1 },
              { name: 'Avanzado limitado / baja escala', id: 2 },
              { name: 'Básico potencial / baja escala', id: 3 },
              { name: 'Avanzado potencial / baja escala', id: 4 },
              { name: 'Básico tradicional / alta escala', id: 5 },
              { name: 'Avanzado limitado / alta escala', id: 6 },
              { name: 'Básico potencial / alta escala', id: 7 },
              { name: 'Avanzado potencial / alta escala', id: 8 }
            ]"
            item-text="name"
            item-value="id"
            label="Habilidades en innovación"
            :readonly="!editable"
            :rules="[(v) => v >= 0 || 'Campo obligatorio']"
          />
          <v-text-field
            v-model="borrowerEditable.password"
            label="Contraseña (introducir sólo si se desea cambiar)"
            :type="showPassword ? 'text' : 'password'"
            :append-icon="showPassword ? 'visibility' : 'visibility_off'"
            :readonly="!editable"
            :rules="formRules.passwordNotRequiredRules"
            @click:append="showPassword = !showPassword"
          />
        </v-form>
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-icon v-if="!editable && permissionsCardsActions.profileData.edit.includes(user.role)" @click="editable = true">edit</v-icon>
        <v-btn v-if="editable" text @click="(editable = false), formatFields()">Cancelar</v-btn>
        <v-btn v-if="editable" text @click="edit">Guardar</v-btn>
      </v-card-actions>
    </v-card>

    <!-- Project -->
    <v-card class="my-8">
      <v-toolbar color="primary" dense>
        <span>Proyectos</span>
      </v-toolbar>

      <v-card-title>
        <v-text-field v-model="search" append-icon="mdi-magnify" label="Buscar" single-line hide-details />
      </v-card-title>

      <v-data-table :headers="tableProjectsHeaders" :items="projects" :search="search">
        <template v-slot:[`item.id`]="{ item }">
          <router-link :to="`/proyectos/${item.id}`">{{ item.id }}</router-link>
        </template>
        <template v-slot:[`item.amount_invested`]="{ item }">
          {{ currency(item.amount_invested, { fromCents: true }) }}
        </template>
        <template v-slot:[`item.cheque.amount`]="{ item }">
          {{ currency(item.amount, { fromCents: true }) }}
        </template>
        <template v-slot:[`item.active_at`]="{ item }">
          {{ formatDateL(item.active_at) }}
        </template>
      </v-data-table>
    </v-card>

    <!-- Form profile image -->
    <v-card class="my-8">
      <v-toolbar color="primary" dense>Imagen de perfil</v-toolbar>
      <v-card-text class="pa-5">
        <v-form ref="formUploadProfileImage" v-model="formUploadProfileImageValid">
          <LazyImage :img="this.profileImageUrl ? this.profileImageUrl : ''" :lazy="lazyProfileImage" />
          <v-file-input
            v-model="profileImage"
            :accept="['.jpeg', '.png', '.jpg']"
            truncate-length="100"
            :clearable="false"
            prepend-icon="image"
            label="Imagen de perfil"
            :rules="formRules.imageRequiredRules"
          />
        </v-form>
      </v-card-text>
      <v-card-actions v-if="permissionsCardsActions.profileImage.edit.includes(user.role)" class="justify-end">
        <v-btn text @click="uploadProfileImage">Guardar</v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import listeners from "@/firebase/listeners";
import _ from "lodash";
import FormRulesMixin from "@/mixins/FormRulesMixin";
import FormatDateMixin from "@/mixins/FormatDateMixin";
import CommonMixin from "@/mixins/CommonMixin";
import Banner from "@/components/elements/Banner";
import LazyImage from "@/components/elements/LazyImage";
import DatePicker from "@/components/elements/DatePicker";
import CountryAutocomplete from "@/components/elements/locations/CountryAutocomplete.vue";
import * as currency from "currency.js";

export default {
  name: "Borrower",
  components: {
    Banner,
    LazyImage,
    DatePicker,
    CountryAutocomplete
  },
  mixins: [FormRulesMixin, FormatDateMixin, CommonMixin],
  data() {
    return {
      // Cards actions permissions
      permissionsCardsActions: {
        profileData: {
          edit: ["SUPERADMIN", "ADMIN", "SUPERTECHNICIAN"]
        },
        profileImage: {
          edit: ["SUPERADMIN", "ADMIN", "SUPERTECHNICIAN"]
        }
      },

      // Borrower projects datatable
      search: "",
      tableProjectsHeaders: [
        { text: "Id", value: "id", sortable: false },
        { text: "Estado", value: "status", sortable: false },
        { text: "Inversión (ML)", value: "amount_invested", sortable: false },
        { text: "Monto cheque", value: "cheque.amount", sortable: false },
        { text: "Fecha de activación", value: "active_at", sortable: false }
      ],

      // Form edit investor
      editable: false,
      formEditBorrowerValid: false,
      borrowerEditable: {},
      showPassword: false,

      // Form upload profile image
      formUploadProfileImageValid: false,
      profileImage: null,
      profileImageUrl: "",
      lazyProfileImage: false
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user.user,
      borrower: (state) => state.borrowers.borrower,
      cities: (state) => state.locations.cities,
      projects: (state) => state.projects.projects
    }),
    _() {
      return _;
    },
    currency() {
      return currency;
    }
  },
  watch: {
    async borrower() {
      await this.formatFields();
    },
    editable() {
      this.$store.commit("SET_EDITING", this.editable);
    }
  },
  async created() {
    this.$store.commit("SET_LOADING", true);
    await listeners.borrower(this.$route.params.id);
    // Push back if id does not exist
    if (this._.isEmpty(this.borrower) || this.borrower.id !== this.$route.params.id) {
      this.$router.push("/prestatarias");
      this.$store.commit("SET_LOADING", false);
      return;
    }
    await this.$store.dispatch("projects/getProjects", {
      filter: { "borrower.id": this.$route.params.id }
    });
    await this.formatFields();
    this.$store.commit("SET_LOADING", false);
  },
  methods: {
    // Format fields editables
    async formatFields() {
      this.borrowerEditable = this._.cloneDeep(this.borrower);
      this.borrowerEditable.date_of_birth = this.formatDateToISO8601(this.borrowerEditable.date_of_birth);
      if (this.borrowerEditable.photo) this.profileImageUrl = await this.getFileFromStorage(this.borrowerEditable.photo);
    },

    // Edit borrower
    async edit() {
      if (this.$refs.formEditBorrower.validate()) {
        let borrowerEditableFormattedData = {
          id: this.borrowerEditable.id,
          email: this.borrowerEditable.email,
          first_name: this.borrowerEditable.first_name,
          last_name: this.borrowerEditable.last_name,
          phone: this.borrowerEditable.phone,
          address: this.borrowerEditable.address,
          country: this.borrowerEditable.country,
          city: this.borrowerEditable.city,
          zone: this.borrowerEditable.zone,
          borrower: {
            finance_skills: this.borrowerEditable.borrower.finance_skills,
            marketing_skills: this.borrowerEditable.borrower.marketing_skills,
            innovation_skills: this.borrowerEditable.borrower.innovation_skills
          }
        };

        if (this.borrowerEditable.password) borrowerEditableFormattedData.password = this.borrowerEditable.password;
        borrowerEditableFormattedData.date_of_birth = this.formatDateToISO8601(this.borrowerEditable.date_of_birth);

        console.log(borrowerEditableFormattedData);

        await this.$store.dispatch("borrowers/editBorrower", borrowerEditableFormattedData);

        this.formatFields();
        this.editable = false;
      }
    },

    // Valid and upload image of borrower's profile
    async uploadProfileImage() {
      if (this.$refs.formUploadProfileImage.validate()) {
        const user_id = this.borrowerEditable.id;
        const file = await this.toBase64(this.profileImage);

        await this.$store.dispatch("addUserPhoto", { user_id, file });
        this.lazyProfileImage = true;
        this.profileImage = null;
        this.$refs.formUploadProfileImage.resetValidation();
      }
    },

    async handleCountrySelection(countryId) {
      await this.$store.dispatch("locations/getCities", { filter: { country: countryId } });
      this.borrowerEditable.country = countryId;
    }
  }
};
</script>
